//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name:'sama-el-baraka-import-footer',
  // data() {
  //   return {
  //     socialList: [
  //       {
  //         icon: "fa-facebook-f",
  //         url: "https://www.facebook.com",
  //       },
  //     ],
  //   };
  // },
};
